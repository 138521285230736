<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <ADatePicker
                    v-model:value="state.params.start_date"
                    :disabled-date="disabledStartDate"
                    placeholder="Tanggal Mulai"
                    style="width:100%;"
                    format="DD MMMM YYYY"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <ADatePicker
                    v-model:value="state.params.end_date"
                    :disabled-date="disabledEndDate"
                    placeholder="Tanggal Akhir"
                    style="width:100%;"
                    format="DD MMMM YYYY"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterDistributor
                    style="width:100%;"
                    v-model:value="state.params.distributor"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterGudang
                    style="width:100%;"
                    v-model:distributor="state.params.distributor"
                    v-model:value="state.params.gudang"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterProduct
                    style="width:100%;"
                    v-model:value="state.params.produk"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <AButton
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </ACol>
        </ARow>

        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12">
            </div>
            <div class="col-lg-6 col-md-12 text-right">
                <ASpace>
                    <ATooltip title="Download excel">
                        <DownloadExcel
                            :url="state.endpoint.current"
                            :params="state.params"
                            namefile="Laporan-Transaksi-E-Pod"
                            @errors="errorMessage"/>
                    </ATooltip>
                </ASpace>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">

                <template #no="{ index }">
                    <span>
                        {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
                    </span>
                </template>
            </MdTable>
        </div>

    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import apiClient from '@/services/axios'
import FilterProduct from '@/components/filter/FilterProduct'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterGudang from '@/components/filter/FilterGudang'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import _ from 'lodash'
import moment from 'moment'
import {
    hasRoles,
    ROLE_ADMIN_BK,
} from '@/helpers'

export default defineComponent({
    components: {
        FilterProduct,
        FilterDistributor,
        FilterGudang,
        DownloadExcel,
    },
    setup() {
        const errorMessage = ref()
        const router = useRouter()
        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Tanggal Transaksi',
                    dataIndex: 'date',
                },
                {
                    title: 'Kode Vendor',
                    dataIndex: 'vendor_code',
                },
                {
                    title: 'Nama Vendor',
                    dataIndex: 'vendor_name',
                },
                {
                    title: 'Kode Product',
                    dataIndex: 'product_code',
                },
                {
                    title: 'Nama Product',
                    dataIndex: 'product_name',
                },
                {
                    title: 'Kode sold to',
                    dataIndex: 'sold_to_code',
                },
                {
                    title: 'Nama sold to',
                    dataIndex: 'sold_to_name',
                },
                {
                    title: 'Kode ship to',
                    dataIndex: 'ship_to_code',
                },
                {
                    title: 'Nama ship to',
                    dataIndex: 'ship_to_name',
                },
                {
                    title: 'ship_to_address',
                    dataIndex: 'ship_to_address',
                },
                {
                    title: 'sal_district_code',
                    dataIndex: 'sal_district_code',
                },
                {
                    title: 'sal_district_name',
                    dataIndex: 'sal_district_name',
                },
                {
                    title: 'subdistrict_code',
                    dataIndex: 'subdistrict_code',
                },
                {
                    title: 'subdistrict_name',
                    dataIndex: 'subdistrict_name',
                },
                {
                    title: 'sal_office_code',
                    dataIndex: 'sal_office_code',
                },
                {
                    title: 'sal_office_name',
                    dataIndex: 'sal_office_name',
                },
                {
                    title: 'plant',
                    dataIndex: 'plant',
                },
                {
                    title: 'do_type',
                    dataIndex: 'do_type',
                },
                {
                    title: 'no_shp_trn',
                    dataIndex: 'no_shp_trn',
                },
                {
                    title: 'org',
                    dataIndex: 'org',
                },
                {
                    title: 'nopol',
                    dataIndex: 'nopol',
                },
                {
                    title: 'vehicle_type',
                    dataIndex: 'vehicle_type',
                },
                {
                    title: 'plate_color',
                    dataIndex: 'plate_color',
                },
                {
                    title: 'driver',
                    dataIndex: 'driver',
                },
                {
                    title: 'no_entry_sheet',
                    dataIndex: 'no_entry_sheet',
                },
                {
                    title: 'uom',
                    dataIndex: 'uom',
                },
                {
                    title: 'qty',
                    dataIndex: 'qty',
                },
                {
                    title: 'rate_cost',
                    dataIndex: 'rate_cost',
                },
                {
                    title: 'shp_cost',
                    dataIndex: 'shp_cost',
                },
                {
                    title: 'so_no',
                    dataIndex: 'so_no',
                },
                {
                    title: 'inco',
                    dataIndex: 'inco',
                },
                {
                    title: 'no_inv_vendor',
                    dataIndex: 'no_inv_vendor',
                },
                {
                    title: 'no_inv_sap',
                    dataIndex: 'no_inv_sap',
                },
                {
                    title: 'accounting_doc',
                    dataIndex: 'accounting_doc',
                },
                {
                    title: 'delivery_date',
                    dataIndex: 'delivery_date',
                },
                {
                    title: 'arrival_date',
                    dataIndex: 'arrival_date',
                },
                {
                    title: 'arrival_clock',
                    dataIndex: 'arrival_clock',
                },
                {
                    title: 'unloading_date',
                    dataIndex: 'unloading_date',
                },
                {
                    title: 'unloading_clock',
                    dataIndex: 'unloading_clock',
                },
                {
                    title: 'invoice_date',
                    dataIndex: 'invoice_date',
                },
                {
                    title: 'last_update_date',
                    dataIndex: 'last_update_date',
                },
                {
                    title: 'clearing_date',
                    dataIndex: 'clearing_date',
                },
                {
                    title: 'qty_ktg_rusak',
                    dataIndex: 'qty_ktg_rusak',
                },
                {
                    title: 'qty_semen_rusak',
                    dataIndex: 'qty_semen_rusak',
                },
                {
                    title: 'total_ktg_rusak',
                    dataIndex: 'total_ktg_rusak',
                },
                {
                    title: 'total_ktg_rezak',
                    dataIndex: 'total_ktg_rezak',
                },
                {
                    title: 'total_semen_rusak',
                    dataIndex: 'total_semen_rusak',
                },
                {
                    title: 'total_klaim_ktg',
                    dataIndex: 'total_klaim_ktg',
                },
                {
                    title: 'total_klaim_semen',
                    dataIndex: 'total_klaim_semen',
                },
                {
                    title: 'redemption_price',
                    dataIndex: 'redemption_price',
                },
                {
                    title: 'pdpks',
                    dataIndex: 'pdpks',
                },
                {
                    title: 'total_klaim_all',
                    dataIndex: 'total_klaim_all',
                },
                {
                    title: 'pengelola',
                    dataIndex: 'pengelola',
                },
                {
                    title: 'nama_pengelola',
                    dataIndex: 'nama_pengelola',
                },
                {
                    title: 'ship_name',
                    dataIndex: 'ship_name',
                },
                {
                    title: 'status',
                    dataIndex: 'status',
                },
                {
                    title: 'status2',
                    dataIndex: 'status2',
                },
                {
                    title: 'no_pajak_ex',
                    dataIndex: 'no_pajak_ex',
                },
                {
                    title: 'billing_ready_date',
                    dataIndex: 'billing_ready_date',
                },
                {
                    title: 'nomorfb',
                    dataIndex: 'nomorfb',
                },
                {
                    title: 'invoice_no',
                    dataIndex: 'invoice_no',
                },
                {
                    title: 'pod_flag',
                    dataIndex: 'pod_flag',
                },
                {
                    title: 'pod_description',
                    dataIndex: 'pod_description',
                },
                {
                    title: 'pod1_evidence',
                    dataIndex: 'pod1_evidence',
                },
                {
                    title: 'pod2_evidence',
                    dataIndex: 'pod2_evidence',
                },
                {
                    title: 'pod_geovence',
                    dataIndex: 'pod_geovence',
                },
                {
                    title: 'spj_no',
                    dataIndex: 'spj_no',
                },
                {
                    title: 'spj_date',
                    dataIndex: 'spj_date',
                },
                {
                    title: 'log_org',
                    dataIndex: 'log_org',
                },
                {
                    title: 'log_pod_flag',
                    dataIndex: 'log_pod_flag',
                },
                {
                    title: 'log_pod_description',
                    dataIndex: 'log_pod_description',
                },
                {
                    title: 'log_pod1_evidence',
                    dataIndex: 'log_pod1_evidence',
                },
                {
                    title: 'log_pod2_evidence',
                    dataIndex: 'log_pod2_evidence',
                },
                {
                    title: 'log_pod_geovence',
                    dataIndex: 'log_pod_geovence',
                },
                {
                    title: 'brand',
                    dataIndex: 'brand',
                },
                {
                    title: 'no_doc_hdr1',
                    dataIndex: 'no_doc_hdr1',
                },
                {
                    title: 'year character',
                    dataIndex: 'year',
                },
                {
                    title: 'receipt_date',
                    dataIndex: 'receipt_date',
                },
            ],
            endpoint: {
                current: '/api/report/laporan-transaksi-epod',
            },
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            isDownloading: false,
            params: {
                q: '',
                produk: [],
                distributor: [],
                gudang: [],
                start_date: moment(new Date()).startOf('month'),
                end_date: moment(new Date()).endOf("month"),
                page: 1,
                "per-page": 10,
            },
        })

        const disabledStartDate = (current) => {
            return current && current > moment(state.params.end_date)
        }

        const disabledEndDate = (current) => {
            return current && moment(state.params.start_date) >= current
        }

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        const queryParams = () => {
            let params = state.params

            if (state.params.start_date) {
                state.params.start_date = moment(state.params.start_date).format('YYYY-MM-DD')
            }

            if (state.params.end_date) {
                state.params.end_date = moment(state.params.end_date).format('YYYY-MM-DD')
            }

            return _.pickBy(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            apiClient
                .get(state.endpoint.current, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        // handle vue
        onMounted(() => {
            fetchDataList()
        })

        return {
            router,
            state,
            fetchDataList,
            handleTableChange,
            errorMessage,
            disabledStartDate,
            disabledEndDate,
            // only variable role
            hasRoles,
            ROLE_ADMIN_BK,
        }
    },
})
</script>
